import { PostData } from '../service/wpApi';
import { Box, Typography } from '@mui/material';
import Rating from './Rating';
import { useNavigate } from 'react-router-dom';

type SmallPostItemProps = {
  oddEven?: 'odd' | 'even',
  post: PostData,
  showRating?: boolean
};

const SmallPostItem = ({ post, oddEven, showRating }: SmallPostItemProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 0.625,
        backgroundColor: oddEven === 'odd' ? '#fafafa' : 'transparent'
      }}
    >
      <Box
        sx={{
          width: 32,
          height: 32,
          mr: 2,
          backgroundImage: `url(${post.acf.icon})`,
          backgroundSize: 'cover',
          cursor: 'pointer'
        }}
        onClick={() => navigate(`/reviews/${post.slug}`)}
      />
      <Typography
        sx={{ flex: 1, fontSize: 16, fontWeight: 500, cursor: 'pointer' }}
        onClick={() => navigate(`/reviews/${post.slug}`)}
      >
        {post.acf.game_name}
      </Typography>
      {showRating && <Rating rating={post.acf.rating} />}
    </Box>
  );
};

export default SmallPostItem;
