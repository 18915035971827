import { Stack } from '@mui/material';
import { Star, StarHalf, StarOutline } from '@mui/icons-material';
import { GameRating } from '../service/wpApi';

type RatingProps = {
  rating: GameRating
};

const Rating = ({ rating }: RatingProps) => {
  const ratingNr = parseInt(rating);
  const starMap: Array<0 | 1 | 2> = [];
  for (let i = 0; i < 5; i++) {
    const ratingNrFiveStar = ratingNr / 2;
    if (i + 1 <= ratingNrFiveStar) {
      starMap[i] = 2;
    } else if (i < ratingNrFiveStar && i + 1 > ratingNrFiveStar) {
      starMap[i] = 1;
    } else {
      starMap[i] = 0;
    }
  }

  return (
    <Stack direction="row" sx={{ display: 'inline-flex', ml: 2, color: '#ff9800' }}>
      {starMap.map((star, index) => {
        if (star === 2) {
          return <Star key={index} />;
        } else if (star === 1) {
          return <StarHalf key={index} />;
        } else {
          return <StarOutline key={index} />;
        }
      })}
    </Stack>
  );
}

export default Rating;