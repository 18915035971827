import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton, LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon, ListItemText, Theme,
  Toolbar,
  Typography, useMediaQuery
} from '@mui/material';
import { Config } from '../config';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { HomeRounded, InfoRounded, SportsEsports } from '@mui/icons-material';
import { useState } from 'react';
import SearchBox from './Search';
import { useAppSelector } from '../hooks';

const TopBar = () => {
  const navigate = useNavigate();
  const loadingBarActive = useAppSelector(state => state.loadingBar.active);
  const tabletOrLarger = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(tabletOrLarger);

  const handleOpenNavMenu = () => {
    setDrawerOpen(true);
  };
  const handleCloseNavMenu = () => {
    setDrawerOpen(false);
  };

  const menuItems = [
    { icon: <HomeRounded/>, title: 'Home', path: '/' },
    { icon: <SportsEsports/>, title: 'Reviews', path: '/reviews' },
    { icon: <InfoRounded/>, title: 'About', path: '/about' }
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: '#333333' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 8,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Frijole, sans-serif',
              fontSize: 24,
              lineHeight: 'normal',
              color: 'primary.main',
              cursor: 'pointer'
            }}
            onClick={() => navigate('/')}
          >
            {Config.SITE_NAME}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: 'white' }}
            >
              <MenuIcon/>
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleCloseNavMenu}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={handleCloseNavMenu}
                onKeyDown={handleCloseNavMenu}
              >
                <List>
                  {menuItems.map((item) => (
                    <Link key={item.title} to={item.path} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <ListItem sx={{ py: 0 }}>
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemButton>
                          <ListItemText primary={item.title}/>
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Box>
            </Drawer>
          </Box>
          {(tabletOrLarger || (!tabletOrLarger && !searchOpen)) && (
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Frijole, sans-serif',
                fontSize: 20,
                lineHeight: 'normal',
                color: 'primary.main',
                cursor: 'pointer'
              }}
              onClick={() => navigate('/')}
            >
              {Config.SITE_NAME}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((item) => (
              <Link key={item.title} to={item.path} style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    display: 'block',
                    mt: 0.5,
                    mr: 4,
                    color: 'white',
                    textTransform: 'uppercase',
                    cursor: 'pointer'
                  }}>
                  {item.title}
                </Typography>
              </Link>
            ))}
          </Box>
          {false && <SearchBox searchOpen={searchOpen} setSearchOpen={setSearchOpen}/>}
        </Toolbar>
      </Container>
      {loadingBarActive && <LinearProgress/>}
    </AppBar>
  );
};

export default TopBar;
