import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ErrorPage from './view/ErrorPage';
import TopBar from './component/TopBar';

type AppProps = {
  showError?: boolean
};

const App = ({ showError }: AppProps) => {
  return (
    <>
      <TopBar />
      <Container
        maxWidth="lg"
        sx={{
          my: { xs: 2, sm: 3 }
        }}
      >
        {!showError ? <Outlet/> : <ErrorPage/>}
      </Container>
    </>
  );
};

export default App;
