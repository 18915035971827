import { Box } from '@mui/material';

type ErrorPageProps = {
  error?: string;
};

const ErrorPage = ({ error }: ErrorPageProps) => {
  let errorMessage = 'Error';
  if (error === '404') {
    errorMessage = 'Page not found';
  }

  return <Box>{errorMessage}</Box>
};

export default ErrorPage;