import { Box, Pagination } from '@mui/material';
import { PostData, useGetPostsAlphabeticallyQuery } from '../service/wpApi';
import React, { useEffect, useState } from 'react';
import SmallPostItem from '../component/SmallPostItem';
import { useAppDispatch } from '../hooks';
import { disable, enable } from '../reducer/loadingBarSlice';

const Reviews = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const { data, isLoading } = useGetPostsAlphabeticallyQuery(page);

  useEffect(() => {
    dispatch(isLoading ? enable() : disable());
  }, [isLoading, dispatch])

  return (
    <Box>
      {data && data.posts && data.posts.map((post: PostData, index) =>
        <SmallPostItem key={index} post={post} oddEven={index % 2 === 0 ? 'odd' : 'even'} showRating={true}/>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {data && data.pageCount &&
          <Pagination
            count={data.pageCount}
            page={page}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
        }
      </Box>

    </Box>
  );
};

export default Reviews;
