import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { GamePlatform, GameRating } from '../service/wpApi';
import Rating from './Rating';
import PlatformIcons from './PlatformIcons';

type StatsTableProps = {
  developer: string,
  platformsAvailable: Array<GamePlatform>,
  platformsPlayed: Array<GamePlatform>,
  publisher: string,
  rating: GameRating,
  releaseYear: number
};

const StatsTable = (
  {
    platformsAvailable,
    platformsPlayed,
    rating,
    releaseYear,
    developer,
    publisher
  }: StatsTableProps) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow key="stats.rating">
              <TableCell><strong>My Rating</strong></TableCell>
              <TableCell align="right"><Rating rating={rating} /></TableCell>
            </TableRow>
            <TableRow key="stats.platforms">
              <TableCell><strong>Platforms</strong></TableCell>
              <TableCell align="right"><PlatformIcons available={platformsAvailable} played={platformsPlayed} /></TableCell>
            </TableRow>
            <TableRow key="stats.release_year">
              <TableCell><strong>Release Year</strong></TableCell>
              <TableCell align="right" sx={{ fontWeight: 500 }}>{releaseYear}</TableCell>
            </TableRow>
            <TableRow key="stats.developer">
              <TableCell><strong>Developer</strong></TableCell>
              <TableCell align="right" sx={{ fontWeight: 500 }}>{developer}</TableCell>
            </TableRow>
            <TableRow key="stats.publisher">
              <TableCell><strong>Publisher</strong></TableCell>
              <TableCell align="right" sx={{ fontWeight: 500 }}>{publisher}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StatsTable;