import { wpApi } from '../service/wpApi';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import loadingBarReducer from '../reducer/loadingBarSlice';

export const store = configureStore({
  reducer: {
    [wpApi.reducerPath]: wpApi.reducer,
    loadingBar: loadingBarReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(wpApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);