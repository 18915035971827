import React from 'react';
import ReactDOM from 'react-dom/client';
import PiwikPro from '@piwikpro/react-piwik-pro';
import './style/index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './view/Home';
import PageBySlug from './view/PageBySlug';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { orange } from '@mui/material/colors';
import Reviews from './view/Reviews';
import ReviewBySlug from './view/ReviewBySlug';

let theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    }
  },
  palette: {
    primary: orange
  },
  typography: {
    h3: {
      fontSize: 24,
      fontWeight: 700,
      marginBottom: 16
    }
  }
});
theme = responsiveFontSizes(theme);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <App showError />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'reviews',
        element: <Reviews />
      },
      {
        path: 'reviews/:slug',
        element: <ReviewBySlug />
      },
      {
        path: ':slug',
        element: <PageBySlug />
      }
    ]
  }
]);

PiwikPro.initialize('d0f66867-9daa-48fa-9fce-747366736ffc', 'https://sockcatgames.containers.piwik.pro');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
