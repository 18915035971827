import { PostData, useGetPostsQuery } from '../service/wpApi';
import React, { useEffect } from 'react';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Post from '../component/Post';
import SmallPostItem from '../component/SmallPostItem';
import { disable, enable } from '../reducer/loadingBarSlice';
import { useAppDispatch } from '../hooks';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { data, isLoading } = useGetPostsQuery();

  useEffect(() => {
    dispatch(isLoading ? enable() : disable());
  }, [isLoading, dispatch])

  const firstPost = data ? data[0] : undefined;
  const recentPosts = data && data.length >= 2 ? data.slice(1) : undefined;

  const reviewsBtn = (
    <Box sx={{ mt: 2, textAlign: 'center' }}>
      <Button variant="contained" onClick={() => navigate('/reviews')}>More reviews</Button>
    </Box>
  );

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      <Box sx={{ flex: 1 }}>
        {firstPost && <Post post={firstPost}/>}
        {isSmUp && reviewsBtn}
      </Box>
      <Box width={{ xs: 'auto', sm: 250 }}>
        <Typography variant="h3">Recent</Typography>
        {recentPosts && recentPosts.map((post: PostData, index) => <SmallPostItem key={index} post={post}/>)}
      </Box>
      {!isSmUp && reviewsBtn}
    </Stack>
  );
};

export default Home;