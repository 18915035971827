import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Config } from '../config';

export type GamePlatform = 'pc' | 'switch' | 'xbox' | 'playstation';

export type GameRating = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

export type GameData = {
  cover_image: string,
  developer: string,
  game_name: string,
  icon: string,
  platforms_available: Array<GamePlatform>,
  platforms_played: Array<GamePlatform>,
  publisher: string,
  rating: GameRating,
  release_year: number
};

export type PostData = {
  acf: GameData,
  content: { protected: boolean, rendered: string },
  date: string,
  id: number,
  modified: string,
  slug: string,
  title: { rendered: string }
};

export const wpApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQuery({ baseUrl: Config.WP_API_URL }),
  endpoints: (builder) => ({
    getPageBySlug: builder.query<PostData | null, string>({
      query: (slug) => `pages?slug=${slug}`,
      transformResponse: (response: Array<PostData>) => {
        if (response && response.length > 0) {
          return response[0];
        }
        return null;
      }
    }),
    getPosts: builder.query<Array<PostData>, void>({
      query: () => 'posts?acf_format=standard&per_page=6'
    }),
    getPostsAlphabetically: builder.query<{ posts: Array<PostData>, pageCount: number }, number>({
      query: (page?: number) => `posts?acf_format=standard&order=asc&orderby=title&per_page=20&page=${page ?? 1}`,
      transformResponse: (posts: Array<PostData>, meta) => {
        return {
          posts,
          pageCount: Number(meta?.response?.headers.get('X-WP-TotalPages'))
        };
      }
    }),
    getReviewBySlug: builder.query<PostData | null, string>({
      query: (slug) => `posts?acf_format=standard&slug=${slug}`,
      transformResponse: (response: Array<PostData>) => {
        if (response && response.length > 0) {
          return response[0];
        }
        return null;
      }
    }),
  })
});

export const {
  useGetPageBySlugQuery,
  useGetPostsQuery,
  useGetPostsAlphabeticallyQuery,
  useGetReviewBySlugQuery
} = wpApi;