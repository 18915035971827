import { useGetReviewBySlugQuery } from '../service/wpApi';
import { useParams } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { disable, enable } from '../reducer/loadingBarSlice';
import { useAppDispatch } from '../hooks';
import Post from '../component/Post';

const ReviewBySlug = () => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetReviewBySlugQuery(slug as string);

  useEffect(() => {
    dispatch(isLoading ? enable() : disable());
  }, [isLoading, dispatch])

  if (isLoading) {
    return (
      <>
        <Skeleton variant="rectangular" width="300px" height="32px" sx={{ my: '0.83em' }} />
        <Skeleton variant="rectangular" sx={{ mt: '1em', mb: '0.5em' }} />
        <Skeleton variant="rectangular" sx={{ my: '0.5em' }} />
        <Skeleton variant="rectangular" sx={{ my: '0.5em' }} />
        <Skeleton variant="rectangular" sx={{ my: '0.5em' }} />
        <Skeleton variant="rectangular" sx={{ my: '0.5em' }} />
      </>
    );
  }

  if (!data) {
    return <ErrorPage error="404"/>;
  }

  return <Post post={data} />;
};

export default ReviewBySlug;
