import { createSlice } from '@reduxjs/toolkit';

export const loadingBarSlice = createSlice({
  name: 'loadingBar',
  initialState: {
    active: false
  },
  reducers: {
    enable: state => {
      state.active = true;
    },
    disable: state => {
      state.active = false;
    }
  }
});

export const { enable, disable } = loadingBarSlice.actions;

export default loadingBarSlice.reducer;
